import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {
    addSale,
    clearErrors,
    getClientOptions,
    getProductOptions,
    getSpecialClientMaterialTransactions,
} from "../../actions/sale";
import SpecialSaleCreateForm from "./components/SpecialSaleCreateForm";
import SimpleSaleCreateForm from "./components/SimpleSaleCreateForm";

const SaleCreateForm = ({
                            sales,
                            loading,
                            error,
                            handleClose,
                            show,
                            getClientOptions,
                            getProductOptions,
                        }) => {

    const [errors, setErrors] = useState({});

    useEffect(() => {
        clearErrors();
        setErrors({});
    }, [sales]);

    const onClose = () => {
        clearErrors();
        setErrors({});
        handleClose();
    };
    const [isSpecialSale, setIsSpecialSale] = useState(false);

    useEffect(() => {
        if (isSpecialSale) {
            getClientOptions({with_supplier: true})
        } else {
            getClientOptions()
        }
    }, [isSpecialSale])


    useEffect(() => {
        getProductOptions({is_for_sale: true});
        if (error && error.hasOwnProperty('errors')) {
            const errorsItems = error.errors;
            let errorObject = {};
            if (error?.errors) {
                Object.keys(error.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [sales])

    return (
        <Fragment>
            <Modal size="lg" show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi sotuv amalga oshirish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckChecked"
                               onChange={e => setIsSpecialSale(!isSpecialSale)}
                               checked={isSpecialSale}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                            Maxsus sotuv
                        </label>
                    </div>
                    {
                        isSpecialSale ? (
                            <SpecialSaleCreateForm />
                        ) : (
                            <SimpleSaleCreateForm />
                        )
                    }


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

SaleCreateForm.propTypes = {
    addSale: PropTypes.func.isRequired,
    getClientOptions: PropTypes.func.isRequired,
    getProductOptions: PropTypes.func.isRequired,
    // getWarehouseOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    sale: state.sale,
    sales: state.sale.sales,
    seller_options: state.sale.seller_options,
    special_client_materials: state.sale.special_client_materials,
    client_options: state.sale.client_options,
    warehouse_options: state.sale.warehouse_options,
    product_options: state.sale.product_options,
    products: state.sale.products,
    loading: state.sale.loading,
    error: state.sale.error,
});


export default connect(mapStateToProps, {
    addSale,
    clearErrors,
    getClientOptions,
    getSpecialClientMaterialTransactions,
    getProductOptions,
    // getWarehouseOptions
})(SaleCreateForm);
